import { Authority } from '@/shared/security/authority';

// prettier-ignore
const Bandeja = () => import('@/firma/bandeja/bandeja.vue');
const Documento = () => import('@/firma/documento/documento.vue');

export default [
  {
    path: '/bandeja',
    name: 'Bandeja',
    component: Bandeja,
    meta: { authorities: [Authority.ADMIN, Authority.USER] },
  },
  {
    path: '/documento/:idDocumento/:idFirmante',
    name: 'Documento',
    component: Documento,
    meta: { authorities: [Authority.ADMIN, Authority.USER] },
  },
  {
    path: '/documento/:idDocumento',
    name: 'Documento',
    component: Documento,
    meta: { authorities: [Authority.ADMIN, Authority.USER] },
  },
];
