/* tslint:disable */
/* eslint-disable */
/**
 * firmaDocumentosMs
 * API para microservicio de firma de documentos
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface DatosProceso
 */
export interface DatosProceso {
    /**
     * Área que genera el documento
     * @type {string}
     * @memberof DatosProceso
     */
    'claveArea': string;
    /**
     * Una cadena que identifica al proceso con el que se generó el documento, será mostrado al usuario
     * @type {string}
     * @memberof DatosProceso
     */
    'proceso': string;
    /**
     * Una cadena que identifica la apricación procedente, será mostrado al usuario
     * @type {string}
     * @memberof DatosProceso
     */
    'aplicacion': string;
    /**
     * Es el identificador de la solicitud, proyecto o grupo de proyectos al que se relaciona el documento
     * @type {string}
     * @memberof DatosProceso
     */
    'identificador': string;
    /**
     * Es un nombre descriptivo del documento, será mostrado al usuario
     * @type {string}
     * @memberof DatosProceso
     */
    'nombre'?: string;
    /**
     * Si no se especifica, por default será Firma Simple
     * @type {string}
     * @memberof DatosProceso
     */
    'tipoFirma'?: DatosProcesoTipoFirmaEnum;
    /**
     * Nombre de la convocatoria
     * @type {string}
     * @memberof DatosProceso
     */
    'convocatoria': string;
    /**
     * etapa en la que se generó el documento
     * @type {string}
     * @memberof DatosProceso
     */
    'etapa': DatosProcesoEtapaEnum;
    /**
     * Nombre del responsable
     * @type {string}
     * @memberof DatosProceso
     */
    'proponente'?: string;
    /**
     * Nombre de la instititución
     * @type {string}
     * @memberof DatosProceso
     */
    'institucion'?: string;
}

export const DatosProcesoTipoFirmaEnum = {
    Simple: 'simple'
} as const;

export type DatosProcesoTipoFirmaEnum = typeof DatosProcesoTipoFirmaEnum[keyof typeof DatosProcesoTipoFirmaEnum];
export const DatosProcesoEtapaEnum = {
    Preregistro: 'PREREGISTRO',
    Registro: 'REGISTRO',
    Evaluacion: 'EVALUACION',
    Formalizacion: 'FORMALIZACION',
    Seguimiento: 'SEGUIMIENTO'
} as const;

export type DatosProcesoEtapaEnum = typeof DatosProcesoEtapaEnum[keyof typeof DatosProcesoEtapaEnum];

/**
 * 
 * @export
 * @interface Documento
 */
export interface Documento {
    /**
     * El identificador del proceso de firmado del documento
     * @type {number}
     * @memberof Documento
     */
    'id'?: number;
    /**
     * Nombre del estado del documento
     * @type {string}
     * @memberof Documento
     */
    'estado'?: string;
    /**
     * Fecha de la última modificación del documento
     * @type {string}
     * @memberof Documento
     */
    'ultimaModificacion'?: string;
    /**
     * Fecha en que se registró el documento en el proceso de firmado
     * @type {string}
     * @memberof Documento
     */
    'fechaRegistro'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentoEstados = {
    EnCaptura: 'EN_CAPTURA',
    EnProceso: 'EN_PROCESO',
    Pausado: 'PAUSADO',
    Firmado: 'FIRMADO',
    Cancelado: 'CANCELADO'
} as const;

export type DocumentoEstados = typeof DocumentoEstados[keyof typeof DocumentoEstados];


/**
 * 
 * @export
 * @interface DocumentoFirmante
 */
export interface DocumentoFirmante {
    /**
     * 
     * @type {number}
     * @memberof DocumentoFirmante
     */
    'idDocumento'?: number;
    /**
     * Nombre de la aplicación que generó el documento
     * @type {string}
     * @memberof DocumentoFirmante
     */
    'aplicacion'?: string;
    /**
     * Nombre del proceso en la aplicación que generó el documento
     * @type {string}
     * @memberof DocumentoFirmante
     */
    'proceso'?: string;
    /**
     * Identificador del documento en la aplicación
     * @type {string}
     * @memberof DocumentoFirmante
     */
    'identificador'?: string;
    /**
     * Nombre del documento
     * @type {string}
     * @memberof DocumentoFirmante
     */
    'nombreDocumento'?: string;
    /**
     * Nombre del documento
     * @type {string}
     * @memberof DocumentoFirmante
     */
    'proponente'?: string;
    /**
     * Nombre del documento
     * @type {string}
     * @memberof DocumentoFirmante
     */
    'institucion'?: string;
    /**
     * 
     * @type {DocumentoEstados}
     * @memberof DocumentoFirmante
     */
    'estadoDocumento'?: DocumentoEstados;
    /**
     * Url del documento
     * @type {string}
     * @memberof DocumentoFirmante
     */
    'uriDocumento'?: string;
    /**
     * Id del filrmante en el documento
     * @type {number}
     * @memberof DocumentoFirmante
     */
    'idFirma'?: number;
    /**
     * 
     * @type {FirmaEstados}
     * @memberof DocumentoFirmante
     */
    'estado'?: FirmaEstados;
    /**
     * Fecha en que se registro como firmante del documento
     * @type {string}
     * @memberof DocumentoFirmante
     */
    'fechaRegistro'?: string;
    /**
     * Fecha en que se le fue asignado para firmar
     * @type {string}
     * @memberof DocumentoFirmante
     */
    'fechaAsignacion'?: string;
    /**
     * Fecha en que se el usuario firmó el documento
     * @type {string}
     * @memberof DocumentoFirmante
     */
    'fechaFirma'?: string;
}
/**
 * 
 * @export
 * @interface DocumentoProceso
 */
export interface DocumentoProceso {
    /**
     * Nombre del archivo
     * @type {string}
     * @memberof DocumentoProceso
     */
    'nombre': string;
    /**
     * Archivo en Base64
     * @type {string}
     * @memberof DocumentoProceso
     */
    'datosProceso'?: string;
}
/**
 * 
 * @export
 * @interface EstatusFirmaMasiva
 */
export interface EstatusFirmaMasiva {
    /**
     * Id de la firma
     * @type {number}
     * @memberof EstatusFirmaMasiva
     */
    'idFirma'?: number;
    /**
     * El estatus actual de la firma
     * @type {string}
     * @memberof EstatusFirmaMasiva
     */
    'estatusActual'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FirmaEstados = {
    Pendiente: 'PENDIENTE',
    ListoParaFirmar: 'LISTO_PARA_FIRMAR',
    EnProceso: 'EN_PROCESO',
    Firmado: 'FIRMADO'
} as const;

export type FirmaEstados = typeof FirmaEstados[keyof typeof FirmaEstados];


/**
 * 
 * @export
 * @interface FirmaEstatus
 */
export interface FirmaEstatus {
    /**
     * 
     * @type {number}
     * @memberof FirmaEstatus
     */
    'idDocumento'?: number;
    /**
     * Nombre de la aplicación que generó el documento
     * @type {string}
     * @memberof FirmaEstatus
     */
    'aplicacion'?: string;
    /**
     * Nombre del proceso en la aplicación que generó el documento
     * @type {string}
     * @memberof FirmaEstatus
     */
    'proceso'?: string;
    /**
     * Identificador del documento en la aplicación
     * @type {string}
     * @memberof FirmaEstatus
     */
    'identificador'?: string;
    /**
     * Identificador del documento en la aplicación
     * @type {string}
     * @memberof FirmaEstatus
     */
    'convocatoria'?: string;
    /**
     * Nombre del documento
     * @type {string}
     * @memberof FirmaEstatus
     */
    'nombreDocumento'?: string;
    /**
     * Url del documento
     * @type {string}
     * @memberof FirmaEstatus
     */
    'uriDocumento'?: string;
    /**
     * 
     * @type {DocumentoEstados}
     * @memberof FirmaEstatus
     */
    'estadoDocumento'?: DocumentoEstados;
    /**
     * Id del filrmante en el documento
     * @type {number}
     * @memberof FirmaEstatus
     */
    'idFirmante'?: number;
    /**
     * 
     * @type {FirmaEstados}
     * @memberof FirmaEstatus
     */
    'estado'?: FirmaEstados;
}
/**
 * 
 * @export
 * @interface Firmante
 */
export interface Firmante {
    /**
     * Número CVU del usuario
     * @type {string}
     * @memberof Firmante
     */
    'cvu': string;
    /**
     * Si se desea que el nombre del firmante aparesca con un prefijo.
     * @type {string}
     * @memberof Firmante
     */
    'prefijo'?: string;
    /**
     * Es el nombre del campo del área donde quedará plasmada la firnama dentro del documento
     * @type {string}
     * @memberof Firmante
     */
    'campoFirma': string;
}
/**
 * 
 * @export
 * @interface FirmantesProceso
 */
export interface FirmantesProceso {
    /**
     * 
     * @type {Array<Firmante>}
     * @memberof FirmantesProceso
     */
    'firmantes': Array<Firmante>;
    /**
     * 
     * @type {DatosProceso}
     * @memberof FirmantesProceso
     */
    'datosProceso': DatosProceso;
    /**
     * 
     * @type {DocumentoProceso}
     * @memberof FirmantesProceso
     */
    'documento'?: DocumentoProceso;
}
/**
 * 
 * @export
 * @interface Problem
 */
export interface Problem {
    /**
     * An absolute URI that identifies the problem type.  When dereferenced, it SHOULD provide human-readable documentation for the problem type (e.g., using HTML). 
     * @type {string}
     * @memberof Problem
     */
    'type'?: string;
    /**
     * A short, summary of the problem type. Written in english and readable for engineers (usually not suited for non technical stakeholders and not localized); example: Service Unavailable 
     * @type {string}
     * @memberof Problem
     */
    'title'?: string;
    /**
     * The HTTP status code generated by the origin server for this occurrence of the problem. 
     * @type {number}
     * @memberof Problem
     */
    'status'?: number;
    /**
     * A human readable explanation specific to this occurrence of the problem. 
     * @type {string}
     * @memberof Problem
     */
    'detail'?: string;
    /**
     * An absolute URI that identifies the specific occurrence of the problem. It may or may not yield further information if dereferenced. 
     * @type {string}
     * @memberof Problem
     */
    'instance'?: string;
}

/**
 * DocumentoApi - axios parameter creator
 * @export
 */
export const DocumentoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Consulta los documentos del firmante en sesión (servicio para el Front)
         * @param {number} [page] Número de página
         * @param {number} [size] Total de elementos por página
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscarDocumentosFirmante: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documentos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Consulta los documentos del firmante en sesión (servicio para el Front)
         * @param {Array<number>} idsFirma Ids de firma de documentos a consultar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscarEstatusDocumentosFirma: async (idsFirma: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idsFirma' is not null or undefined
            assertParamExists('buscarEstatusDocumentosFirma', 'idsFirma', idsFirma)
            const localVarPath = `/documentos/estatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idsFirma) {
                localVarQueryParameter['idsFirma'] = idsFirma;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancela el proceso de firmado al documento que le corresponde el id
         * @param {number} documentoId Id del documento a cargar
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelarProcesoDocumento: async (documentoId: number, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('cancelarProcesoDocumento', 'documentoId', documentoId)
            const localVarPath = `/documentos/{documentoId}`
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Método para firmar más de un documento a la vez
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmaDocumentosMasivos: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documentos/firmar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Se firmará el documento que corresponda al documentoId (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmarDocumento: async (documentoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('firmarDocumento', 'documentoId', documentoId)
            const localVarPath = `/documentos/{documentoId}/firmar`
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Agrega la información del proceso de firmado al documento que le corresponde el id
         * @param {FirmantesProceso} firmantesProceso Información del documento y firmantes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guardarDatosProcesoDocumento: async (firmantesProceso: FirmantesProceso, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmantesProceso' is not null or undefined
            assertParamExists('guardarDatosProcesoDocumento', 'firmantesProceso', firmantesProceso)
            const localVarPath = `/documentos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(firmantesProceso, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Consulta el estatus de la firma del documento para el usuario con el turno especificado (servicio para el Front)
         * @param {number} turnoId Id del turno del firmante
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmaEstatus: async (turnoId: number, documentoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'turnoId' is not null or undefined
            assertParamExists('obtenerDocumentoFirmaEstatus', 'turnoId', turnoId)
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('obtenerDocumentoFirmaEstatus', 'documentoId', documentoId)
            const localVarPath = `/documentos/{documentoId}/{turnoId}/estatus`
                .replace(`{${"turnoId"}}`, encodeURIComponent(String(turnoId)))
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Consulta el documento (en Base64) si es que está asignado al usuario firmante (servicio para el Front)
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmante: async (documentoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('obtenerDocumentoFirmante', 'documentoId', documentoId)
            const localVarPath = `/documentos/firmante/{documentoId}`
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Consulta el estatus de la firma del documento para el usuario (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmanteEstatus: async (documentoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('obtenerDocumentoFirmanteEstatus', 'documentoId', documentoId)
            const localVarPath = `/documentos/{documentoId}/firmante/estatus`
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Con el **id** que retornó el registro del documento *([POST]/documentos/documento)* se descargará la última versión del documento.
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoPorId: async (documentoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('obtenerDocumentoPorId', 'documentoId', documentoId)
            const localVarPath = `/documentos/{documentoId}`
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Con el **id** que retornó el registro del documento *([POST]/documentos/documento)* se obtiene el estatus del documento.
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerEstatusDocumentoPorId: async (documentoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('obtenerEstatusDocumentoPorId', 'documentoId', documentoId)
            const localVarPath = `/documentos/{documentoId}/estatus`
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentoApi - functional programming interface
 * @export
 */
export const DocumentoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentoApiAxiosParamCreator(configuration)
    return {
        /**
         * Consulta los documentos del firmante en sesión (servicio para el Front)
         * @param {number} [page] Número de página
         * @param {number} [size] Total de elementos por página
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buscarDocumentosFirmante(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentoFirmante>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buscarDocumentosFirmante(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Consulta los documentos del firmante en sesión (servicio para el Front)
         * @param {Array<number>} idsFirma Ids de firma de documentos a consultar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buscarEstatusDocumentosFirma(idsFirma: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EstatusFirmaMasiva>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buscarEstatusDocumentosFirma(idsFirma, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Cancela el proceso de firmado al documento que le corresponde el id
         * @param {number} documentoId Id del documento a cargar
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelarProcesoDocumento(documentoId: number, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelarProcesoDocumento(documentoId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Método para firmar más de un documento a la vez
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firmaDocumentosMasivos(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firmaDocumentosMasivos(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Se firmará el documento que corresponda al documentoId (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firmarDocumento(documentoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firmarDocumento(documentoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Agrega la información del proceso de firmado al documento que le corresponde el id
         * @param {FirmantesProceso} firmantesProceso Información del documento y firmantes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guardarDatosProcesoDocumento(firmantesProceso: FirmantesProceso, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guardarDatosProcesoDocumento(firmantesProceso, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Consulta el estatus de la firma del documento para el usuario con el turno especificado (servicio para el Front)
         * @param {number} turnoId Id del turno del firmante
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumentoFirmaEstatus(turnoId: number, documentoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FirmaEstatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumentoFirmaEstatus(turnoId, documentoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Consulta el documento (en Base64) si es que está asignado al usuario firmante (servicio para el Front)
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumentoFirmante(documentoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumentoFirmante(documentoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Consulta el estatus de la firma del documento para el usuario (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumentoFirmanteEstatus(documentoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FirmaEstatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumentoFirmanteEstatus(documentoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Con el **id** que retornó el registro del documento *([POST]/documentos/documento)* se descargará la última versión del documento.
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumentoPorId(documentoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumentoPorId(documentoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Con el **id** que retornó el registro del documento *([POST]/documentos/documento)* se obtiene el estatus del documento.
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerEstatusDocumentoPorId(documentoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Documento>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerEstatusDocumentoPorId(documentoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentoApi - factory interface
 * @export
 */
export const DocumentoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentoApiFp(configuration)
    return {
        /**
         * Consulta los documentos del firmante en sesión (servicio para el Front)
         * @param {number} [page] Número de página
         * @param {number} [size] Total de elementos por página
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscarDocumentosFirmante(page?: number, size?: number, options?: any): AxiosPromise<Array<DocumentoFirmante>> {
            return localVarFp.buscarDocumentosFirmante(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Consulta los documentos del firmante en sesión (servicio para el Front)
         * @param {Array<number>} idsFirma Ids de firma de documentos a consultar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscarEstatusDocumentosFirma(idsFirma: Array<number>, options?: any): AxiosPromise<Array<EstatusFirmaMasiva>> {
            return localVarFp.buscarEstatusDocumentosFirma(idsFirma, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancela el proceso de firmado al documento que le corresponde el id
         * @param {number} documentoId Id del documento a cargar
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelarProcesoDocumento(documentoId: number, body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelarProcesoDocumento(documentoId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Método para firmar más de un documento a la vez
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmaDocumentosMasivos(requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.firmaDocumentosMasivos(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Se firmará el documento que corresponda al documentoId (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmarDocumento(documentoId: number, options?: any): AxiosPromise<void> {
            return localVarFp.firmarDocumento(documentoId, options).then((request) => request(axios, basePath));
        },
        /**
         * Agrega la información del proceso de firmado al documento que le corresponde el id
         * @param {FirmantesProceso} firmantesProceso Información del documento y firmantes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guardarDatosProcesoDocumento(firmantesProceso: FirmantesProceso, options?: any): AxiosPromise<void> {
            return localVarFp.guardarDatosProcesoDocumento(firmantesProceso, options).then((request) => request(axios, basePath));
        },
        /**
         * Consulta el estatus de la firma del documento para el usuario con el turno especificado (servicio para el Front)
         * @param {number} turnoId Id del turno del firmante
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmaEstatus(turnoId: number, documentoId: number, options?: any): AxiosPromise<FirmaEstatus> {
            return localVarFp.obtenerDocumentoFirmaEstatus(turnoId, documentoId, options).then((request) => request(axios, basePath));
        },
        /**
         * Consulta el documento (en Base64) si es que está asignado al usuario firmante (servicio para el Front)
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmante(documentoId: number, options?: any): AxiosPromise<string> {
            return localVarFp.obtenerDocumentoFirmante(documentoId, options).then((request) => request(axios, basePath));
        },
        /**
         * Consulta el estatus de la firma del documento para el usuario (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmanteEstatus(documentoId: number, options?: any): AxiosPromise<FirmaEstatus> {
            return localVarFp.obtenerDocumentoFirmanteEstatus(documentoId, options).then((request) => request(axios, basePath));
        },
        /**
         * Con el **id** que retornó el registro del documento *([POST]/documentos/documento)* se descargará la última versión del documento.
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoPorId(documentoId: number, options?: any): AxiosPromise<any> {
            return localVarFp.obtenerDocumentoPorId(documentoId, options).then((request) => request(axios, basePath));
        },
        /**
         * Con el **id** que retornó el registro del documento *([POST]/documentos/documento)* se obtiene el estatus del documento.
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerEstatusDocumentoPorId(documentoId: number, options?: any): AxiosPromise<Documento> {
            return localVarFp.obtenerEstatusDocumentoPorId(documentoId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentoApi - object-oriented interface
 * @export
 * @class DocumentoApi
 * @extends {BaseAPI}
 */
export class DocumentoApi extends BaseAPI {
    /**
     * Consulta los documentos del firmante en sesión (servicio para el Front)
     * @param {number} [page] Número de página
     * @param {number} [size] Total de elementos por página
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentoApi
     */
    public buscarDocumentosFirmante(page?: number, size?: number, options?: AxiosRequestConfig) {
        return DocumentoApiFp(this.configuration).buscarDocumentosFirmante(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Consulta los documentos del firmante en sesión (servicio para el Front)
     * @param {Array<number>} idsFirma Ids de firma de documentos a consultar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentoApi
     */
    public buscarEstatusDocumentosFirma(idsFirma: Array<number>, options?: AxiosRequestConfig) {
        return DocumentoApiFp(this.configuration).buscarEstatusDocumentosFirma(idsFirma, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancela el proceso de firmado al documento que le corresponde el id
     * @param {number} documentoId Id del documento a cargar
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentoApi
     */
    public cancelarProcesoDocumento(documentoId: number, body?: string, options?: AxiosRequestConfig) {
        return DocumentoApiFp(this.configuration).cancelarProcesoDocumento(documentoId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Método para firmar más de un documento a la vez
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentoApi
     */
    public firmaDocumentosMasivos(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return DocumentoApiFp(this.configuration).firmaDocumentosMasivos(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Se firmará el documento que corresponda al documentoId (servicio para el Front)
     * @param {number} documentoId Id del documento a revisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentoApi
     */
    public firmarDocumento(documentoId: number, options?: AxiosRequestConfig) {
        return DocumentoApiFp(this.configuration).firmarDocumento(documentoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Agrega la información del proceso de firmado al documento que le corresponde el id
     * @param {FirmantesProceso} firmantesProceso Información del documento y firmantes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentoApi
     */
    public guardarDatosProcesoDocumento(firmantesProceso: FirmantesProceso, options?: AxiosRequestConfig) {
        return DocumentoApiFp(this.configuration).guardarDatosProcesoDocumento(firmantesProceso, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Consulta el estatus de la firma del documento para el usuario con el turno especificado (servicio para el Front)
     * @param {number} turnoId Id del turno del firmante
     * @param {number} documentoId Id del documento a revisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentoApi
     */
    public obtenerDocumentoFirmaEstatus(turnoId: number, documentoId: number, options?: AxiosRequestConfig) {
        return DocumentoApiFp(this.configuration).obtenerDocumentoFirmaEstatus(turnoId, documentoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Consulta el documento (en Base64) si es que está asignado al usuario firmante (servicio para el Front)
     * @param {number} documentoId Id del documento a descargar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentoApi
     */
    public obtenerDocumentoFirmante(documentoId: number, options?: AxiosRequestConfig) {
        return DocumentoApiFp(this.configuration).obtenerDocumentoFirmante(documentoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Consulta el estatus de la firma del documento para el usuario (servicio para el Front)
     * @param {number} documentoId Id del documento a revisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentoApi
     */
    public obtenerDocumentoFirmanteEstatus(documentoId: number, options?: AxiosRequestConfig) {
        return DocumentoApiFp(this.configuration).obtenerDocumentoFirmanteEstatus(documentoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Con el **id** que retornó el registro del documento *([POST]/documentos/documento)* se descargará la última versión del documento.
     * @param {number} documentoId Id del documento a descargar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentoApi
     */
    public obtenerDocumentoPorId(documentoId: number, options?: AxiosRequestConfig) {
        return DocumentoApiFp(this.configuration).obtenerDocumentoPorId(documentoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Con el **id** que retornó el registro del documento *([POST]/documentos/documento)* se obtiene el estatus del documento.
     * @param {number} documentoId Id del documento a descargar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentoApi
     */
    public obtenerEstatusDocumentoPorId(documentoId: number, options?: AxiosRequestConfig) {
        return DocumentoApiFp(this.configuration).obtenerEstatusDocumentoPorId(documentoId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FirmaApi - axios parameter creator
 * @export
 */
export const FirmaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Método para firmar más de un documento a la vez
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmaDocumentosMasivos: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documentos/firmar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Se firmará el documento que corresponda al documentoId (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmarDocumento: async (documentoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('firmarDocumento', 'documentoId', documentoId)
            const localVarPath = `/documentos/{documentoId}/firmar`
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FirmaApi - functional programming interface
 * @export
 */
export const FirmaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FirmaApiAxiosParamCreator(configuration)
    return {
        /**
         * Método para firmar más de un documento a la vez
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firmaDocumentosMasivos(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firmaDocumentosMasivos(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Se firmará el documento que corresponda al documentoId (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firmarDocumento(documentoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firmarDocumento(documentoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FirmaApi - factory interface
 * @export
 */
export const FirmaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FirmaApiFp(configuration)
    return {
        /**
         * Método para firmar más de un documento a la vez
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmaDocumentosMasivos(requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.firmaDocumentosMasivos(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Se firmará el documento que corresponda al documentoId (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmarDocumento(documentoId: number, options?: any): AxiosPromise<void> {
            return localVarFp.firmarDocumento(documentoId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FirmaApi - object-oriented interface
 * @export
 * @class FirmaApi
 * @extends {BaseAPI}
 */
export class FirmaApi extends BaseAPI {
    /**
     * Método para firmar más de un documento a la vez
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmaApi
     */
    public firmaDocumentosMasivos(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return FirmaApiFp(this.configuration).firmaDocumentosMasivos(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Se firmará el documento que corresponda al documentoId (servicio para el Front)
     * @param {number} documentoId Id del documento a revisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmaApi
     */
    public firmarDocumento(documentoId: number, options?: AxiosRequestConfig) {
        return FirmaApiFp(this.configuration).firmarDocumento(documentoId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FirmaDocumentosApi - axios parameter creator
 * @export
 */
export const FirmaDocumentosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Consulta los documentos del firmante en sesión (servicio para el Front)
         * @param {number} [page] Número de página
         * @param {number} [size] Total de elementos por página
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscarDocumentosFirmante: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documentos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Consulta los documentos del firmante en sesión (servicio para el Front)
         * @param {Array<number>} idsFirma Ids de firma de documentos a consultar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscarEstatusDocumentosFirma: async (idsFirma: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idsFirma' is not null or undefined
            assertParamExists('buscarEstatusDocumentosFirma', 'idsFirma', idsFirma)
            const localVarPath = `/documentos/estatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idsFirma) {
                localVarQueryParameter['idsFirma'] = idsFirma;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancela el proceso de firmado al documento que le corresponde el id
         * @param {number} documentoId Id del documento a cargar
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelarProcesoDocumento: async (documentoId: number, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('cancelarProcesoDocumento', 'documentoId', documentoId)
            const localVarPath = `/documentos/{documentoId}`
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Método para firmar más de un documento a la vez
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmaDocumentosMasivos: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documentos/firmar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Se firmará el documento que corresponda al documentoId (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmarDocumento: async (documentoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('firmarDocumento', 'documentoId', documentoId)
            const localVarPath = `/documentos/{documentoId}/firmar`
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Agrega la información del proceso de firmado al documento que le corresponde el id
         * @param {FirmantesProceso} firmantesProceso Información del documento y firmantes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guardarDatosProcesoDocumento: async (firmantesProceso: FirmantesProceso, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'firmantesProceso' is not null or undefined
            assertParamExists('guardarDatosProcesoDocumento', 'firmantesProceso', firmantesProceso)
            const localVarPath = `/documentos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(firmantesProceso, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Consulta el estatus de la firma del documento para el usuario con el turno especificado (servicio para el Front)
         * @param {number} turnoId Id del turno del firmante
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmaEstatus: async (turnoId: number, documentoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'turnoId' is not null or undefined
            assertParamExists('obtenerDocumentoFirmaEstatus', 'turnoId', turnoId)
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('obtenerDocumentoFirmaEstatus', 'documentoId', documentoId)
            const localVarPath = `/documentos/{documentoId}/{turnoId}/estatus`
                .replace(`{${"turnoId"}}`, encodeURIComponent(String(turnoId)))
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Consulta el documento (en Base64) si es que está asignado al usuario firmante (servicio para el Front)
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmante: async (documentoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('obtenerDocumentoFirmante', 'documentoId', documentoId)
            const localVarPath = `/documentos/firmante/{documentoId}`
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Consulta el estatus de la firma del documento para el usuario (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmanteEstatus: async (documentoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('obtenerDocumentoFirmanteEstatus', 'documentoId', documentoId)
            const localVarPath = `/documentos/{documentoId}/firmante/estatus`
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Con el **id** que retornó el registro del documento *([POST]/documentos/documento)* se descargará la última versión del documento.
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoPorId: async (documentoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('obtenerDocumentoPorId', 'documentoId', documentoId)
            const localVarPath = `/documentos/{documentoId}`
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Con el **id** que retornó el registro del documento *([POST]/documentos/documento)* se obtiene el estatus del documento.
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerEstatusDocumentoPorId: async (documentoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('obtenerEstatusDocumentoPorId', 'documentoId', documentoId)
            const localVarPath = `/documentos/{documentoId}/estatus`
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FirmaDocumentosApi - functional programming interface
 * @export
 */
export const FirmaDocumentosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FirmaDocumentosApiAxiosParamCreator(configuration)
    return {
        /**
         * Consulta los documentos del firmante en sesión (servicio para el Front)
         * @param {number} [page] Número de página
         * @param {number} [size] Total de elementos por página
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buscarDocumentosFirmante(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentoFirmante>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buscarDocumentosFirmante(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Consulta los documentos del firmante en sesión (servicio para el Front)
         * @param {Array<number>} idsFirma Ids de firma de documentos a consultar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buscarEstatusDocumentosFirma(idsFirma: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EstatusFirmaMasiva>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buscarEstatusDocumentosFirma(idsFirma, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Cancela el proceso de firmado al documento que le corresponde el id
         * @param {number} documentoId Id del documento a cargar
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelarProcesoDocumento(documentoId: number, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelarProcesoDocumento(documentoId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Método para firmar más de un documento a la vez
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firmaDocumentosMasivos(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firmaDocumentosMasivos(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Se firmará el documento que corresponda al documentoId (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firmarDocumento(documentoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firmarDocumento(documentoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Agrega la información del proceso de firmado al documento que le corresponde el id
         * @param {FirmantesProceso} firmantesProceso Información del documento y firmantes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guardarDatosProcesoDocumento(firmantesProceso: FirmantesProceso, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guardarDatosProcesoDocumento(firmantesProceso, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Consulta el estatus de la firma del documento para el usuario con el turno especificado (servicio para el Front)
         * @param {number} turnoId Id del turno del firmante
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumentoFirmaEstatus(turnoId: number, documentoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FirmaEstatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumentoFirmaEstatus(turnoId, documentoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Consulta el documento (en Base64) si es que está asignado al usuario firmante (servicio para el Front)
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumentoFirmante(documentoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumentoFirmante(documentoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Consulta el estatus de la firma del documento para el usuario (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumentoFirmanteEstatus(documentoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FirmaEstatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumentoFirmanteEstatus(documentoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Con el **id** que retornó el registro del documento *([POST]/documentos/documento)* se descargará la última versión del documento.
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumentoPorId(documentoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumentoPorId(documentoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Con el **id** que retornó el registro del documento *([POST]/documentos/documento)* se obtiene el estatus del documento.
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerEstatusDocumentoPorId(documentoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Documento>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerEstatusDocumentoPorId(documentoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FirmaDocumentosApi - factory interface
 * @export
 */
export const FirmaDocumentosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FirmaDocumentosApiFp(configuration)
    return {
        /**
         * Consulta los documentos del firmante en sesión (servicio para el Front)
         * @param {number} [page] Número de página
         * @param {number} [size] Total de elementos por página
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscarDocumentosFirmante(page?: number, size?: number, options?: any): AxiosPromise<Array<DocumentoFirmante>> {
            return localVarFp.buscarDocumentosFirmante(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Consulta los documentos del firmante en sesión (servicio para el Front)
         * @param {Array<number>} idsFirma Ids de firma de documentos a consultar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscarEstatusDocumentosFirma(idsFirma: Array<number>, options?: any): AxiosPromise<Array<EstatusFirmaMasiva>> {
            return localVarFp.buscarEstatusDocumentosFirma(idsFirma, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancela el proceso de firmado al documento que le corresponde el id
         * @param {number} documentoId Id del documento a cargar
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelarProcesoDocumento(documentoId: number, body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelarProcesoDocumento(documentoId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Método para firmar más de un documento a la vez
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmaDocumentosMasivos(requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.firmaDocumentosMasivos(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Se firmará el documento que corresponda al documentoId (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmarDocumento(documentoId: number, options?: any): AxiosPromise<void> {
            return localVarFp.firmarDocumento(documentoId, options).then((request) => request(axios, basePath));
        },
        /**
         * Agrega la información del proceso de firmado al documento que le corresponde el id
         * @param {FirmantesProceso} firmantesProceso Información del documento y firmantes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guardarDatosProcesoDocumento(firmantesProceso: FirmantesProceso, options?: any): AxiosPromise<void> {
            return localVarFp.guardarDatosProcesoDocumento(firmantesProceso, options).then((request) => request(axios, basePath));
        },
        /**
         * Consulta el estatus de la firma del documento para el usuario con el turno especificado (servicio para el Front)
         * @param {number} turnoId Id del turno del firmante
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmaEstatus(turnoId: number, documentoId: number, options?: any): AxiosPromise<FirmaEstatus> {
            return localVarFp.obtenerDocumentoFirmaEstatus(turnoId, documentoId, options).then((request) => request(axios, basePath));
        },
        /**
         * Consulta el documento (en Base64) si es que está asignado al usuario firmante (servicio para el Front)
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmante(documentoId: number, options?: any): AxiosPromise<string> {
            return localVarFp.obtenerDocumentoFirmante(documentoId, options).then((request) => request(axios, basePath));
        },
        /**
         * Consulta el estatus de la firma del documento para el usuario (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmanteEstatus(documentoId: number, options?: any): AxiosPromise<FirmaEstatus> {
            return localVarFp.obtenerDocumentoFirmanteEstatus(documentoId, options).then((request) => request(axios, basePath));
        },
        /**
         * Con el **id** que retornó el registro del documento *([POST]/documentos/documento)* se descargará la última versión del documento.
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoPorId(documentoId: number, options?: any): AxiosPromise<any> {
            return localVarFp.obtenerDocumentoPorId(documentoId, options).then((request) => request(axios, basePath));
        },
        /**
         * Con el **id** que retornó el registro del documento *([POST]/documentos/documento)* se obtiene el estatus del documento.
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerEstatusDocumentoPorId(documentoId: number, options?: any): AxiosPromise<Documento> {
            return localVarFp.obtenerEstatusDocumentoPorId(documentoId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FirmaDocumentosApi - object-oriented interface
 * @export
 * @class FirmaDocumentosApi
 * @extends {BaseAPI}
 */
export class FirmaDocumentosApi extends BaseAPI {
    /**
     * Consulta los documentos del firmante en sesión (servicio para el Front)
     * @param {number} [page] Número de página
     * @param {number} [size] Total de elementos por página
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmaDocumentosApi
     */
    public buscarDocumentosFirmante(page?: number, size?: number, options?: AxiosRequestConfig) {
        return FirmaDocumentosApiFp(this.configuration).buscarDocumentosFirmante(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Consulta los documentos del firmante en sesión (servicio para el Front)
     * @param {Array<number>} idsFirma Ids de firma de documentos a consultar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmaDocumentosApi
     */
    public buscarEstatusDocumentosFirma(idsFirma: Array<number>, options?: AxiosRequestConfig) {
        return FirmaDocumentosApiFp(this.configuration).buscarEstatusDocumentosFirma(idsFirma, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancela el proceso de firmado al documento que le corresponde el id
     * @param {number} documentoId Id del documento a cargar
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmaDocumentosApi
     */
    public cancelarProcesoDocumento(documentoId: number, body?: string, options?: AxiosRequestConfig) {
        return FirmaDocumentosApiFp(this.configuration).cancelarProcesoDocumento(documentoId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Método para firmar más de un documento a la vez
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmaDocumentosApi
     */
    public firmaDocumentosMasivos(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return FirmaDocumentosApiFp(this.configuration).firmaDocumentosMasivos(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Se firmará el documento que corresponda al documentoId (servicio para el Front)
     * @param {number} documentoId Id del documento a revisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmaDocumentosApi
     */
    public firmarDocumento(documentoId: number, options?: AxiosRequestConfig) {
        return FirmaDocumentosApiFp(this.configuration).firmarDocumento(documentoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Agrega la información del proceso de firmado al documento que le corresponde el id
     * @param {FirmantesProceso} firmantesProceso Información del documento y firmantes.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmaDocumentosApi
     */
    public guardarDatosProcesoDocumento(firmantesProceso: FirmantesProceso, options?: AxiosRequestConfig) {
        return FirmaDocumentosApiFp(this.configuration).guardarDatosProcesoDocumento(firmantesProceso, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Consulta el estatus de la firma del documento para el usuario con el turno especificado (servicio para el Front)
     * @param {number} turnoId Id del turno del firmante
     * @param {number} documentoId Id del documento a revisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmaDocumentosApi
     */
    public obtenerDocumentoFirmaEstatus(turnoId: number, documentoId: number, options?: AxiosRequestConfig) {
        return FirmaDocumentosApiFp(this.configuration).obtenerDocumentoFirmaEstatus(turnoId, documentoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Consulta el documento (en Base64) si es que está asignado al usuario firmante (servicio para el Front)
     * @param {number} documentoId Id del documento a descargar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmaDocumentosApi
     */
    public obtenerDocumentoFirmante(documentoId: number, options?: AxiosRequestConfig) {
        return FirmaDocumentosApiFp(this.configuration).obtenerDocumentoFirmante(documentoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Consulta el estatus de la firma del documento para el usuario (servicio para el Front)
     * @param {number} documentoId Id del documento a revisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmaDocumentosApi
     */
    public obtenerDocumentoFirmanteEstatus(documentoId: number, options?: AxiosRequestConfig) {
        return FirmaDocumentosApiFp(this.configuration).obtenerDocumentoFirmanteEstatus(documentoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Con el **id** que retornó el registro del documento *([POST]/documentos/documento)* se descargará la última versión del documento.
     * @param {number} documentoId Id del documento a descargar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmaDocumentosApi
     */
    public obtenerDocumentoPorId(documentoId: number, options?: AxiosRequestConfig) {
        return FirmaDocumentosApiFp(this.configuration).obtenerDocumentoPorId(documentoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Con el **id** que retornó el registro del documento *([POST]/documentos/documento)* se obtiene el estatus del documento.
     * @param {number} documentoId Id del documento a descargar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmaDocumentosApi
     */
    public obtenerEstatusDocumentoPorId(documentoId: number, options?: AxiosRequestConfig) {
        return FirmaDocumentosApiFp(this.configuration).obtenerEstatusDocumentoPorId(documentoId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FirmantesApi - axios parameter creator
 * @export
 */
export const FirmantesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Consulta los documentos del firmante en sesión (servicio para el Front)
         * @param {number} [page] Número de página
         * @param {number} [size] Total de elementos por página
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscarDocumentosFirmante: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documentos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Consulta los documentos del firmante en sesión (servicio para el Front)
         * @param {Array<number>} idsFirma Ids de firma de documentos a consultar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscarEstatusDocumentosFirma: async (idsFirma: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idsFirma' is not null or undefined
            assertParamExists('buscarEstatusDocumentosFirma', 'idsFirma', idsFirma)
            const localVarPath = `/documentos/estatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idsFirma) {
                localVarQueryParameter['idsFirma'] = idsFirma;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Método para firmar más de un documento a la vez
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmaDocumentosMasivos: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documentos/firmar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Se firmará el documento que corresponda al documentoId (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmarDocumento: async (documentoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('firmarDocumento', 'documentoId', documentoId)
            const localVarPath = `/documentos/{documentoId}/firmar`
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Consulta el documento (en Base64) si es que está asignado al usuario firmante (servicio para el Front)
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmante: async (documentoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('obtenerDocumentoFirmante', 'documentoId', documentoId)
            const localVarPath = `/documentos/firmante/{documentoId}`
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FirmantesApi - functional programming interface
 * @export
 */
export const FirmantesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FirmantesApiAxiosParamCreator(configuration)
    return {
        /**
         * Consulta los documentos del firmante en sesión (servicio para el Front)
         * @param {number} [page] Número de página
         * @param {number} [size] Total de elementos por página
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buscarDocumentosFirmante(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentoFirmante>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buscarDocumentosFirmante(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Consulta los documentos del firmante en sesión (servicio para el Front)
         * @param {Array<number>} idsFirma Ids de firma de documentos a consultar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buscarEstatusDocumentosFirma(idsFirma: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EstatusFirmaMasiva>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buscarEstatusDocumentosFirma(idsFirma, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Método para firmar más de un documento a la vez
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firmaDocumentosMasivos(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firmaDocumentosMasivos(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Se firmará el documento que corresponda al documentoId (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firmarDocumento(documentoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firmarDocumento(documentoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Consulta el documento (en Base64) si es que está asignado al usuario firmante (servicio para el Front)
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumentoFirmante(documentoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumentoFirmante(documentoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FirmantesApi - factory interface
 * @export
 */
export const FirmantesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FirmantesApiFp(configuration)
    return {
        /**
         * Consulta los documentos del firmante en sesión (servicio para el Front)
         * @param {number} [page] Número de página
         * @param {number} [size] Total de elementos por página
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscarDocumentosFirmante(page?: number, size?: number, options?: any): AxiosPromise<Array<DocumentoFirmante>> {
            return localVarFp.buscarDocumentosFirmante(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Consulta los documentos del firmante en sesión (servicio para el Front)
         * @param {Array<number>} idsFirma Ids de firma de documentos a consultar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscarEstatusDocumentosFirma(idsFirma: Array<number>, options?: any): AxiosPromise<Array<EstatusFirmaMasiva>> {
            return localVarFp.buscarEstatusDocumentosFirma(idsFirma, options).then((request) => request(axios, basePath));
        },
        /**
         * Método para firmar más de un documento a la vez
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmaDocumentosMasivos(requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.firmaDocumentosMasivos(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Se firmará el documento que corresponda al documentoId (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firmarDocumento(documentoId: number, options?: any): AxiosPromise<void> {
            return localVarFp.firmarDocumento(documentoId, options).then((request) => request(axios, basePath));
        },
        /**
         * Consulta el documento (en Base64) si es que está asignado al usuario firmante (servicio para el Front)
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmante(documentoId: number, options?: any): AxiosPromise<string> {
            return localVarFp.obtenerDocumentoFirmante(documentoId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FirmantesApi - object-oriented interface
 * @export
 * @class FirmantesApi
 * @extends {BaseAPI}
 */
export class FirmantesApi extends BaseAPI {
    /**
     * Consulta los documentos del firmante en sesión (servicio para el Front)
     * @param {number} [page] Número de página
     * @param {number} [size] Total de elementos por página
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmantesApi
     */
    public buscarDocumentosFirmante(page?: number, size?: number, options?: AxiosRequestConfig) {
        return FirmantesApiFp(this.configuration).buscarDocumentosFirmante(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Consulta los documentos del firmante en sesión (servicio para el Front)
     * @param {Array<number>} idsFirma Ids de firma de documentos a consultar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmantesApi
     */
    public buscarEstatusDocumentosFirma(idsFirma: Array<number>, options?: AxiosRequestConfig) {
        return FirmantesApiFp(this.configuration).buscarEstatusDocumentosFirma(idsFirma, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Método para firmar más de un documento a la vez
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmantesApi
     */
    public firmaDocumentosMasivos(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return FirmantesApiFp(this.configuration).firmaDocumentosMasivos(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Se firmará el documento que corresponda al documentoId (servicio para el Front)
     * @param {number} documentoId Id del documento a revisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmantesApi
     */
    public firmarDocumento(documentoId: number, options?: AxiosRequestConfig) {
        return FirmantesApiFp(this.configuration).firmarDocumento(documentoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Consulta el documento (en Base64) si es que está asignado al usuario firmante (servicio para el Front)
     * @param {number} documentoId Id del documento a descargar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirmantesApi
     */
    public obtenerDocumentoFirmante(documentoId: number, options?: AxiosRequestConfig) {
        return FirmantesApiFp(this.configuration).obtenerDocumentoFirmante(documentoId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PdfApi - axios parameter creator
 * @export
 */
export const PdfApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Consulta el documento (en Base64) si es que está asignado al usuario firmante (servicio para el Front)
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmante: async (documentoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('obtenerDocumentoFirmante', 'documentoId', documentoId)
            const localVarPath = `/documentos/firmante/{documentoId}`
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PdfApi - functional programming interface
 * @export
 */
export const PdfApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PdfApiAxiosParamCreator(configuration)
    return {
        /**
         * Consulta el documento (en Base64) si es que está asignado al usuario firmante (servicio para el Front)
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumentoFirmante(documentoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumentoFirmante(documentoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PdfApi - factory interface
 * @export
 */
export const PdfApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PdfApiFp(configuration)
    return {
        /**
         * Consulta el documento (en Base64) si es que está asignado al usuario firmante (servicio para el Front)
         * @param {number} documentoId Id del documento a descargar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmante(documentoId: number, options?: any): AxiosPromise<string> {
            return localVarFp.obtenerDocumentoFirmante(documentoId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PdfApi - object-oriented interface
 * @export
 * @class PdfApi
 * @extends {BaseAPI}
 */
export class PdfApi extends BaseAPI {
    /**
     * Consulta el documento (en Base64) si es que está asignado al usuario firmante (servicio para el Front)
     * @param {number} documentoId Id del documento a descargar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfApi
     */
    public obtenerDocumentoFirmante(documentoId: number, options?: AxiosRequestConfig) {
        return PdfApiFp(this.configuration).obtenerDocumentoFirmante(documentoId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TurnoIdApi - axios parameter creator
 * @export
 */
export const TurnoIdApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Consulta el estatus de la firma del documento para el usuario con el turno especificado (servicio para el Front)
         * @param {number} turnoId Id del turno del firmante
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmaEstatus: async (turnoId: number, documentoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'turnoId' is not null or undefined
            assertParamExists('obtenerDocumentoFirmaEstatus', 'turnoId', turnoId)
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('obtenerDocumentoFirmaEstatus', 'documentoId', documentoId)
            const localVarPath = `/documentos/{documentoId}/{turnoId}/estatus`
                .replace(`{${"turnoId"}}`, encodeURIComponent(String(turnoId)))
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Consulta el estatus de la firma del documento para el usuario (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmanteEstatus: async (documentoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoId' is not null or undefined
            assertParamExists('obtenerDocumentoFirmanteEstatus', 'documentoId', documentoId)
            const localVarPath = `/documentos/{documentoId}/firmante/estatus`
                .replace(`{${"documentoId"}}`, encodeURIComponent(String(documentoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TurnoIdApi - functional programming interface
 * @export
 */
export const TurnoIdApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TurnoIdApiAxiosParamCreator(configuration)
    return {
        /**
         * Consulta el estatus de la firma del documento para el usuario con el turno especificado (servicio para el Front)
         * @param {number} turnoId Id del turno del firmante
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumentoFirmaEstatus(turnoId: number, documentoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FirmaEstatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumentoFirmaEstatus(turnoId, documentoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Consulta el estatus de la firma del documento para el usuario (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumentoFirmanteEstatus(documentoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FirmaEstatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumentoFirmanteEstatus(documentoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TurnoIdApi - factory interface
 * @export
 */
export const TurnoIdApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TurnoIdApiFp(configuration)
    return {
        /**
         * Consulta el estatus de la firma del documento para el usuario con el turno especificado (servicio para el Front)
         * @param {number} turnoId Id del turno del firmante
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmaEstatus(turnoId: number, documentoId: number, options?: any): AxiosPromise<FirmaEstatus> {
            return localVarFp.obtenerDocumentoFirmaEstatus(turnoId, documentoId, options).then((request) => request(axios, basePath));
        },
        /**
         * Consulta el estatus de la firma del documento para el usuario (servicio para el Front)
         * @param {number} documentoId Id del documento a revisar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoFirmanteEstatus(documentoId: number, options?: any): AxiosPromise<FirmaEstatus> {
            return localVarFp.obtenerDocumentoFirmanteEstatus(documentoId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TurnoIdApi - object-oriented interface
 * @export
 * @class TurnoIdApi
 * @extends {BaseAPI}
 */
export class TurnoIdApi extends BaseAPI {
    /**
     * Consulta el estatus de la firma del documento para el usuario con el turno especificado (servicio para el Front)
     * @param {number} turnoId Id del turno del firmante
     * @param {number} documentoId Id del documento a revisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TurnoIdApi
     */
    public obtenerDocumentoFirmaEstatus(turnoId: number, documentoId: number, options?: AxiosRequestConfig) {
        return TurnoIdApiFp(this.configuration).obtenerDocumentoFirmaEstatus(turnoId, documentoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Consulta el estatus de la firma del documento para el usuario (servicio para el Front)
     * @param {number} documentoId Id del documento a revisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TurnoIdApi
     */
    public obtenerDocumentoFirmanteEstatus(documentoId: number, options?: AxiosRequestConfig) {
        return TurnoIdApiFp(this.configuration).obtenerDocumentoFirmanteEstatus(documentoId, options).then((request) => request(this.axios, this.basePath));
    }
}


