
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class PopoverComponent extends Vue {
  @Prop({ required: true })
  id: string;

  @Prop({ default: '' })
  label: string;

  @Prop()
  popOverDisabled: boolean;
}
