var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "wrapper",
      class: { toggled: _vm.sidebarOpen },
      attrs: { id: "app" },
    },
    [
      _c("ribbon", { staticStyle: { position: "fixed" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "sidebar-wrapper" } }, [_c("sidebar")], 1),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "page-content-wrapper" } },
        [
          _c(
            "header",
            {
              staticClass: "header-conacyt",
              class: _vm.$route.path !== "/" ? "rizoma" : "rizoma-home",
            },
            [_c("jhi-navbar"), _vm._v(" "), _c("dataUser")],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "container" }, [_c("router-view")], 1),
          _vm._v(" "),
          _c("jhi-footer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }