var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-navbar",
    [
      _c(
        "b-navbar-toggle",
        {
          staticClass: "jh-navbar-toggler d-lg-none",
          attrs: {
            right: "",
            href: "javascript:void(0);",
            "data-toggle": "collapse",
            target: "header-tabs",
            "aria-expanded": "false",
            "aria-label": "Toggle navigation",
          },
        },
        [_c("font-awesome-icon", { attrs: { icon: "bars" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { "is-nav": "", id: "header-tabs" } },
        [
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "pointer",
                  class: { "router-link-active": _vm.subIsActive("/account") },
                  attrs: {
                    right: "",
                    href: "javascript:void(0);",
                    id: "account-menu",
                    "active-class": "active",
                    "data-cy": "accountMenu",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "navbar-dropdown-menu",
                      attrs: { slot: "button-content" },
                      slot: "button-content",
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "user" } }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "no-bold",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("global.menu.account.main")
                            ),
                          },
                        },
                        [_vm._v(" Account ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "data-cy": "logout",
                            id: "logout",
                            "active-class": "active",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.logout()
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "sign-out-alt" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.logout")
                                ),
                              },
                            },
                            [_vm._v("Sign out")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "data-cy": "login",
                            id: "login",
                            "active-class": "active",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openLogin()
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "sign-in-alt" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.login")
                                ),
                              },
                            },
                            [_vm._v("Sign in")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }