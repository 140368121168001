
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

@Component({
  computed: {
    ...mapGetters(['authenticated', 'account']),
  },
})
export default class DataUserSidebarComponent extends Vue {
  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }
}
