var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "user-info_ text" }, [
      _c(
        "div",
        {
          staticClass: "count__text",
          domProps: { textContent: _vm._s(_vm.$t("global.title")) },
        },
        [_vm._v("firmaDocumentosGW")]
      ),
    ]),
    _vm._v(" "),
    _c("hr", { staticClass: "divider" }),
    _vm._v(" "),
    _c("div", { staticClass: "user-info_ footer-" }, [
      _c("span", [_vm._v("Correo")]),
      _vm._v(" "),
      _c("small", { staticClass: "d-block" }, [
        _vm._v(" " + _vm._s(_vm.account.email) + " "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }