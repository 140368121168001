var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("b-popover", {
    attrs: {
      disabled: _vm.popOverDisabled,
      target: _vm.id,
      triggers: "hover",
      placement: "right",
      boundaryPadding: "50",
      boundary: "window",
    },
    on: {
      "update:disabled": function ($event) {
        _vm.popOverDisabled = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_c("span", [_vm._v(_vm._s(_vm.label))])]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }