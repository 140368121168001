
import { mapGetters } from 'vuex';
import { Component, Inject, Vue } from 'vue-property-decorator';
import AccountService from '@/account/account.service';

@Component({
  computed: {
    ...mapGetters(['account']),
  },
})
export default class DataUserComponent extends Vue {
  @Inject('accountService') private accountService: () => AccountService;

  mounted() {
    setTimeout(() => {
      if (this.authenticated && this.$router.currentRoute.name === 'Home') {
        this.$router.push(`/bandeja`);
      }
    }, 2000);
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }
}
