import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import Ribbon from '@/core/ribbon/ribbon.vue';
import JhiFooter from '@/core/jhi-footer/jhi-footer.vue';
import JhiNavbar from '@/core/jhi-navbar/jhi-navbar.vue';
import SidebarComponent from '@/core/sidebar/sidebar.vue';

import '@/shared/config/dayjs';
import DataUserComponent from '@/core/user/data-user.vue';

@Component({
  components: {
    ribbon: Ribbon,
    'jhi-navbar': JhiNavbar,
    'jhi-footer': JhiFooter,
    sidebar: SidebarComponent,
    dataUser: DataUserComponent,
  },
  computed: {
    ...mapGetters(['sidebarOpen']),
  },
})
export default class App extends Vue {
  sidebarOpen!: boolean;
  acount: any;
}
