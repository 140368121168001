var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "footer" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row align-items-center" }, [
        _c("div", { staticClass: "col-4" }, [
          _c("p", [
            _vm._v("©" + _vm._s(_vm.today) + " "),
            _c(
              "span",
              { domProps: { textContent: _vm._s(_vm.$t("footer.copyright")) } },
              [_vm._v("Conacyt")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c(
              "b-link",
              {
                attrs: { href: "#aviso" },
                domProps: { textContent: _vm._s(_vm.$t("footer.privacy")) },
              },
              [_vm._v("Privacy")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c(
              "b-link",
              {
                attrs: { href: "#terminos" },
                domProps: { textContent: _vm._s(_vm.$t("footer.terms")) },
              },
              [_vm._v("Terms")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }