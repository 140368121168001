import { render, staticRenderFns } from "./sidebar.vue?vue&type=template&id=4614de12&scoped=true&"
import script from "./sidebar.component.ts?vue&type=script&lang=ts&aria-label=Navegaci%C3%B3n%20lateral&"
export * from "./sidebar.component.ts?vue&type=script&lang=ts&aria-label=Navegaci%C3%B3n%20lateral&"
import style0 from "./sidebar.vue?vue&type=style&index=0&id=4614de12&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4614de12",
  null
  
)

export default component.exports